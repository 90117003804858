@font-face {
  font-family: "DMS-700";
  src: url("assets/fonts/DMS-700.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "DMS-500";
  src: url("assets/fonts/DMS-500.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "DMS-400";
  src: url("assets/fonts/DMS-400.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

html {
  line-height: 1.17;
  -webkit-text-size-adjust: 100%;
  color: #EEEEEE;
  background: #1E1E1E;
  font-family: "DMS-700";
}
* {
  scroll-behavior: smooth;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

main {
  display: block;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

hr {
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
  overflow: visible;
  /* 2 */
}

pre {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/* Text-level semantics
   ========================================================================== */

/**
 * Remove the gray background on active links in IE 10.
 */

a, a:active, a:hover {
  background-color: transparent;
  text-decoration: none;
  color: #BDBDBD;
}

/**
 * 1. Remove the bottom border in Chrome 57-
 * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
 */

abbr[title] {
  border-bottom: none;
  /* 1 */
  text-decoration: underline;
  /* 2 */
  text-decoration: underline dotted;
  /* 2 */
}

/**
 * Add the correct font weight in Chrome, Edge, and Safari.
 */

b,
strong {
  font-weight: bolder;
}

a {
  text-decoration: none;
}

/**
 * 1. Correct the inheritance and scaling of font size in all browsers.
 * 2. Correct the odd `em` font sizing in all browsers.
 */

code,
kbd,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 2 */
}

/**
 * Add the correct font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` elements from affecting the line height in
 * all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/* Embedded content
   ========================================================================== */

/**
 * Remove the border on images inside links in IE 10.
 */

img {
  border-style: none;
}

/* Forms
   ========================================================================== */

/**
 * 1. Change the font styles in all browsers.
 * 2. Remove the margin in Firefox and Safari.
 */

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  /* 1 */
  font-size: 100%;
  /* 1 */
  line-height: 1.15;
  /* 1 */
  margin: 0;

  /* 2 */
}

input,
input:focus ,
input:hover  {
  border: none;
  outline: none;
  background: none;
}

/**
 * Show the overflow in IE.
 * 1. Show the overflow in Edge.
 */

button,
input {
  /* 1 */
  overflow: visible;

}

/**
 * Remove the inheritance of text transform in Edge, Firefox, and IE.
 * 1. Remove the inheritance of text transform in Firefox.
 */

button,
select {
  /* 1 */
  text-transform: none;
}

/**
 * Correct the inability to style clickable types in iOS and Safari.
 */

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

/**
 * Remove the inner border and padding in Firefox.
 */

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

/**
 * Restore the focus styles unset by the previous rule.
 */

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

button,
button:active,
button:focus{
  border: none;
  outline: none;
  background: none;
}

/**
 * Correct the padding in Firefox.
 */

fieldset {
  padding: 0.35em 0.75em 0.625em;
}

/**
 * 1. Correct the text wrapping in Edge and IE.
 * 2. Correct the color inheritance from `fieldset` elements in IE.
 * 3. Remove the padding so developers are not caught out when they zero out
 *    `fieldset` elements in all browsers.
 */

legend {
  box-sizing: border-box;
  /* 1 */
  color: inherit;
  /* 2 */
  display: table;
  /* 1 */
  max-width: 100%;
  /* 1 */
  padding: 0;
  /* 3 */
  white-space: normal;
  /* 1 */
}

/**
 * Add the correct vertical alignment in Chrome, Firefox, and Opera.
 */

progress {
  vertical-align: baseline;
}

/**
 * Remove the default vertical scrollbar in IE 10+.
 */

textarea {
  overflow: auto;
}

/**
 * 1. Add the correct box sizing in IE 10.
 * 2. Remove the padding in IE 10.
 */

[type="checkbox"],
[type="radio"] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/**
 * Correct the cursor style of increment and decrement buttons in Chrome.
 */

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * 1. Correct the odd appearance in Chrome and Safari.
 * 2. Correct the outline style in Safari.
 */

[type="search"] {
  -webkit-appearance: textfield;
  /* 1 */
  outline-offset: -2px;
  /* 2 */
}

/**
 * Remove the inner padding in Chrome and Safari on macOS.
 */

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

/**
 * 1. Correct the inability to style clickable types in iOS and Safari.
 * 2. Change font properties to `inherit` in Safari.
 */

::-webkit-file-upload-button {
  -webkit-appearance: button;
  /* 1 */
  font: inherit;
  /* 2 */
}

/* Interactive
   ========================================================================== */

/*
 * Add the correct display in Edge, IE 10+, and Firefox.
 */

details {
  display: block;
}

/*
 * Add the correct display in all browsers.
 */

summary {
  display: list-item;
}

/* Misc
   ========================================================================== */

/**
 * Add the correct display in IE 10+.
 */

template {
  display: none;
}

/**
 * Add the correct display in IE 10.
 */

[hidden] {
  display: none;
}

#root {
  overflow: hidden;

}

.color-white {
  color: #FFFF;
}

.color-white-2 {
  color: #E8E8E8;
}

.color-gray {
  color: #8E8E8E;
}

.color-gray-2 {
  color: #949494;
}

.color-blue {
  color: #0052FF;
}

.color-gold {
  color: #F2BD02;
}
.color-green {
  color: #2EEBA0;
}

.color-purple {
  color: #CB72FF;
}


.color-black {
  color: #0B0B0B;
}

.color-golden {
  color: #E2C080;
  mix-blend-mode: normal;
  text-shadow: 0px 0px 8px rgba(221, 207, 167, 0.6), 0px 4px 8px rgba(0, 0, 0, 0.8);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
}

.bg-black {
  background: #1E1E1E;
}

.size-0 {
  font-family: 'DMS-400';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.size-1 {
  font-family: 'DMS-500';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
}

.size-1-2 {
  font-family: 'DMS-500';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.size-2 {
  font-family: 'DMS-500';
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
}

.size-3 {
  font-family: 'DMS-500';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
}

.size-3-2 {
  font-family: 'DMS-700';
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
}

.size-3-3 {
  font-family: 'DMS-500';
  font-style: normal;
  font-weight: 500;
  font-size: 36px;
  line-height: 54px;
}

.size-4 {
  font-family: 'DMS-700';
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 60px;
}

.size-5 {
  font-family: 'DMS-700';
  font-style: normal;
  font-weight: 700;
  font-size: 56px;
  line-height: 84px;
}


.text-uppercase {
  text-transform: uppercase;
}

.text-capital {
  text-transform: capitalize;
}

input,
input:active,
input:focus {
  border: none;
  outline: none;
}

input::placeholder {
  color: #828282;
}

.text-gradient {
  background: linear-gradient(90deg, #FFFC02 0%, #E88422 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
}

@media only screen and (max-width: 1199px) {
  .size-1 {
    font-family: 'DMS-500';
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  .size-1-2 {
    font-family: 'DMS-400';
    font-weight: 400;
    font-size: 15px;
    line-height: 28px;
  }
  
  
  .size-2 {
    font-family: 'DMS-500';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }
  
  .size-3 {
    font-size: 20px;
    line-height: 29px;
  }

  .size-3-2 {
    font-size: 14px;
    font-family: 'DMS-500';
    font-weight: 500;
    line-height: 20px;
  }

  .size-3-3 {
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
  }

  .size-4 {
    font-size: 16px;
    font-family: 'DMS-500';
    font-weight: 400;
    line-height: 24px;
  }
  .size-5 {
    font-size: 24px;
    line-height: 36px;
    font-family: 'DMS-500';
    font-weight: 500;
  }
}

/* .Toastify__toast-container */
.Toastify__toast-container {
  -webkit-transform: translate3d(0, 0, 1px);
  transform: translate3d(0, 0, 1px);
}